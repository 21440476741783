.logo {
	display: flex;
	align-items: center;
	img {
		margin-right: 10px;
	}
}

.link {
	text-decoration: none;
	color: #fff;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 9px 56.793px 9px 48px;
	align-items: center;
	gap: 297.207px;
	background: #fff;

	a {
		text-decoration: none;
		display: flex;
		align-items: center;

		#logo-title {
			color: #000;
			font-family: Raleway;
			font-size: 21.313px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}
}

.sections-menu {
	display: flex;
	padding: 0px 50px;
	align-items: flex-end;
	background: #2f2f2f;

	.selected {
		border-bottom: 2px solid #4df7b9;
	}
}

#dropdown-section-menu-button {
	width: 168px;
	background-color: #2f2f2f;
	border-radius: 0;
	color: #fff;
	height: 50px;
	cursor: pointer;
}

.dropdown-section-menu-option {
	width: 168px;
}

.dropdown-user-menu-option {
	width: 216px;
}

.dropdown-section-menu-option, .dropdown-user-menu-option {
	a {
		text-decoration: none;

		&:hover > div {
			color: #00c795;
			font-family: "SF Pro";
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px;
			text-decoration-line: underline;
		}
	}
}
